import Showing from '@/constants/Showing';

export const bedroomsOptions = ['Studio', '1', '2', '3', '4', '5', '6', '7', '8'];
export const bathroomsOptions = [0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 11, 12];
export const DEFAULT_EMPTY_SCREENING_INPUT = 'Screening not required';
export const defaultUnitScreeningPayload = {
  move_in_cost: null,
  general_notes: null,
  availability_date: null,
  maximum_allowed_tenants: null,
  minimum_lease_length: null,
  move_in_date: null,
  screening_criteria: null,
};
export const editSectionsDefinitions = [
  {
    default: true,
    label: 'Property & unit',
    name: 'specifications',
    isVisible: () => true,
  },
  {
    label: 'Weekly report',
    name: 'reports',
    isVisible: ({ _, showingProfile }) => showingProfile.type !== Showing.TYPE_PUBLISH_LISTING,
  },
  {
    label: 'Tours limit',
    name: 'tours-limit',
    isVisible: ({ property, showingProfile }) => (
      property.market_id
      && (
        [Showing.TYPE_SELF_SHOWING, Showing.TYPE_PUBLISH_LISTING, Showing.TYPE_OPEN_HOUSE].includes(showingProfile.type) === false
        || (showingProfile.type === Showing.TYPE_OPEN_HOUSE && showingProfile.max_budgeted_tours)
      )
    ),
  },
  {
    label: 'General info',
    name: 'info',
    isVisible: () => true,
  },
  {
    label: 'Access instructions',
    name: 'access-instructions',
    isVisible: ({ _, showingProfile }) => showingProfile.type !== Showing.TYPE_PUBLISH_LISTING,
  },
  {
    label: 'Vacancy',
    name: 'vacancy',
    isVisible: ({ _, showingProfile }) => showingProfile.type !== Showing.TYPE_PUBLISH_LISTING,
  },
  {
    label: 'Showing schedule',
    name: 'showing-schedule',
    isVisible: ({ showingProfile }) => !showingProfile.is_self_showing && showingProfile.type !== Showing.TYPE_PUBLISH_LISTING,
  },
  {
    label: 'Details for prospects',
    name: 'details-prospects',
    isVisible: () => true,
  },
];
